export default class Hover {
    constructor(hover, index) {
        this.hover = hover
        this.content = this.hover.querySelector('.content')
        this.top = this.hover.getBoundingClientRect().top
        this.perc = (window.innerHeight * 13) / 100

        this.scrollTop = this.scrollTop.bind(this)
        this.init()
    }

    init() {
        this.addListeners()
    }

    addListeners() {
        console.log(this.content)
        console.log(this.top)
        console.log(this.top - this.perc)
        this.content.addEventListener('mouseenter', this.scrollTop)
    }

    scrollTop(){
        console.log('enter')

        setTimeout(() => {
            window.scrollTo({
                top: this.top - this.perc,
                behavior: 'smooth'
            })
        }, 1000)
    }

    destroy() {
        //   this.title.removeEventListener('click', this.clickAction)
        // this.reset()
    }

    static resetAll() {
        
    }

    static destroyAll() {
        
    }

    static init() {
        Hover.items = Array.from(document.querySelectorAll('.section--image-description')).map(
            (hover, index) => new Hover(hover, index)
        )
    }
}
