export default class Menu {
    constructor(node) {
        this.node = node

        this.body = document.querySelector('body')
        this.menu = document.querySelector('#header-menu')

        this.prevScrollpos = window.pageYOffset

        //   this.clickOpenClose = this.clickOpenClose.bind(this)
        this.scrollShowHide = this.scrollShowHide.bind(this)

        this.init()
    }

    init() {
        console.debug('  -> Menu init')
        this.reset()
        this.addListeners()
    }

    addListeners() {
        window.addEventListener('scroll', this.scrollShowHide)
    }

    scrollShowHide() {
        var currentScrollPos = window.pageYOffset
        
        if (!this.node.classList.contains('open')) {
            if (this.prevScrollpos > currentScrollPos) {

                if (currentScrollPos == 0) {

                    this.node.classList.remove('shadow')
                } else {

                    if (!this.node.classList.contains('shadow')) {
                        this.node.classList.add('shadow')
                    }
                }

                this.node.style.transform = 'translateY(0)'
            } else {
                if(currentScrollPos > 0) this.node.style.transform = 'translateY(-150px)'
            }

            this.prevScrollpos = currentScrollPos
        }
    }

    reset() {
        console.debug('  -> Menu reset')
    }

    static destroy() {
        console.debug('  -> Menu destroy')

        this.reset()

        //   this.buttonBurger.removeEventListener('click', this.clickOpen)
        //   this.buttonClose.removeEventListener('click', this.clickClose)
    }

    static init() {
        console.debug('== Menu component ==')
        Menu.item = new Menu(document.querySelector('.header'))
    }
}
