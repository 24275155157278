import Animations from './modules/Animations'
// import Hover from './modules/Hover'
import Menu from './modules/Menu'

export default class Main {
  constructor() {
    this.init()
  }

  init() {
    window.addEventListener('load', () => {
      Menu.init()
      Animations.init()
      // Hover.init()
    })
  }
}

const main = new Main()
