import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import {SplitText} from 'gsap/SplitText'

gsap.registerPlugin(ScrollTrigger)
// gsap.registerPlugin(SplitText)

export default class Animations {
    static init() {
        console.debug('== Animations components ==')

        const main = document.querySelector('.main-wrapper')

        // Homepage hero animation
        // if (main.classList.contains('front-page')) {
        console.debug('  -> Animate main viewport')

        const headerWrapper = document.querySelector('.header')
        const sectionHero = document.querySelector('.section--hero')
        const title = sectionHero.querySelector('.content .top-content .title')
        const items = sectionHero.querySelectorAll('.bottom-content .item')
        const sentence = sectionHero.querySelectorAll('.bottom-content .sentence')

        const tlMainViewport = gsap.timeline()
        const sentenceView = gsap.timeline()

        tlMainViewport
            .from(title, {
                autoAlpha: 0,
                // delay: -0.725,
                duration: 1,
                ease: 'expo.out',
            })

        tlMainViewport
            .from(sentence, {
                autoAlpha: 0,
                duration: 0.2,
                delay: -0.1,
                y: 30,
                ease: 'expo.out',
            })

        setTimeout(() => {
            items.forEach((item, index) => {
                sentenceView
                    .from(item, {
                        autoAlpha: 0,
                        duration: 0.25,
                        // delay: 0.1,
                        y: 30,
                        ease: 'expo.out',
                    })
            })

            sentenceView
                .from(headerWrapper, {
                    autoAlpha: 0,
                    delay: -0.1,
                    duration: 0.275,
                    ease: 'expo.out',
                })

        }, 600)

        // General animations

        console.debug('  -> Animate general components')

        if (window.innerWidth < 768) {

            const animateRight = document.querySelectorAll('.animate-right')
            if (animateRight.length != 0) {
                animateHorizontal(animateRight, 20)
            }

            const animateLeft = document.querySelectorAll('.animate-left')
            if (animateLeft.length != 0) {
                animateHorizontal(animateLeft, -20)
            }

            const animateTop = document.querySelectorAll('.animate-top')
            if (animateTop.length != 0) {
                animateVertical(animateTop, -20)
            }

            const animateFadeItems = document.querySelectorAll('.animate-fade')
            if (animateFadeItems.length != 0) {
                animateFade(animateFadeItems)
            }
        }
    }

    static destroyScrolls() {
        ScrollTrigger.killAll()

    }
}

function animateHorizontal(items, offset) {
    items.forEach((item) => {
        let tlItem = gsap.timeline({ paused: true })

        tlItem.from(item, {
            autoAlpha: 0,
            duration: 1,
            x: offset,
            ease: 'expo.out',
        })

        ScrollTrigger.create({
            trigger: item,
            start: 'top 85%',
            end: 'bottom 85%',
            animation: tlItem,
            markers: false,
            toggleActions: 'play none none none',
        })
    })
}

function animateVertical(items, offset, start = 'top 95%') {
    items.forEach((item) => {
        let tlItem = gsap.timeline({ paused: true })

        tlItem.from(item, {
            autoAlpha: 0,
            duration: 1,
            y: offset,
            ease: 'expo.out',
        })

        ScrollTrigger.create({
            trigger: item,
            start: start,
            end: 'bottom 90%',
            animation: tlItem,
            markers: false,
            toggleActions: 'play none none none',
        })
    })
}

function animateFade(items) {
    items.forEach((item) => {
        let tlItem = gsap.timeline({ paused: true })

        tlItem.from(item, {
            autoAlpha: 0,
            duration: 1,
            ease: 'expo.out',
        })

        ScrollTrigger.create({
            trigger: item,
            start: 'top 85%',
            end: 'bottom 85%',
            animation: tlItem,
            markers: false,
            toggleActions: 'play none none none',
        })
    })
}

function slideHeroAnimate(slide) {
    const label = slide.querySelector('.label')
    const title = slide.querySelector('.title')
    const text = slide.querySelector('.text')
    const link = slide.querySelector('.link')

    const tlSlideHero = gsap.timeline()
    tlSlideHero
        .from(label, {
            autoAlpha: 0,
            delay: 0.25,
            duration: 1,
            x: -20,
            ease: 'expo.out',
        })
        .from(title, {
            autoAlpha: 0,
            delay: -0.75,
            duration: 1,
            x: -20,
            ease: 'expo.out',
        })
        .from(text, {
            autoAlpha: 0,
            delay: -0.75,
            duration: 1,
            x: -20,
            ease: 'expo.out',
        })
        .from(link, {
            autoAlpha: 0,
            delay: -0.75,
            duration: 0.9,
            x: -20,
            ease: 'expo.out',
        })
}
